import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Tos from "./Tos";
import Donate from "./Donate";
import SignupChurch from "./SignupChurch";

const Download = () => {
  if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.appscanfly.associalize";
  } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
    window.location.href =
      "https://apps.apple.com/us/app/associalize/id6451225842";
  } else {
    window.location.href = "/";
  }

  return null;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/terms-of-service" element={<Tos />} />
        <Route path="/privacy" element={<Tos />} />
        <Route path="/download" element={<Download />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/signup-church" element={<SignupChurch />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
