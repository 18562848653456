import React from "react";
import logo from "./logo.png";
import "./App.css";
import googleImage from "./google-play-badge.png";
import appstore from "./appstore.png";
import preview from "./preview.png";
import { Box, Grid, Typography } from "@mui/material";

export const Footer = () => (
  <div className="footer">
    <p> ©2022 Apps Can Fly, LLC</p>
    <a href="mailto:support@appscanfly.com">
      <i
        className="fa fa-envelope"
        style={{ fontSize: "24px", color: "white", marginRight: "2px" }}
      ></i>
      <p> Contact Us</p>
    </a>
  </div>
);

const Screenshots = () => {
  return (
    <div className="left">
      <img src={preview} alt="preview-inmage" />
    </div>
  );
};
const Info = () => {
  return (
    <div className="right">
      <div className="logo">
        {/* <img src={logo} alt="logo" /> */}
        <span className="logo1">Associalize</span>
      </div>
      <div className="tag">Connecting you with your association</div>
      <ul className="fetaures fa-ul">
        <li>
          <i className="fa-li fa fa-check-square" />
          Post Announcment & Events
        </li>
        <li>
          <i className="fa-li fa fa-check-square" />
          Make and Manage Payments for members
        </li>
        <li>
          <i className="fa-li fa fa-check-square" />
          Chat and Share Photos
        </li>
      </ul>

      <div className="links">
        <a
          href="https://play.google.com/store/apps/details?id=com.appscanfly.associalize"
          target="_blank"
          rel="noreferrer"
        >
          <img src={googleImage} alt="google-play" />
        </a>
        <a
          href="https://apps.apple.com/us/app/associalize/id6451225842"
          target="_blank"
          rel="noreferrer"
        >
          <img src={appstore} alt="appstore" />
        </a>
      </div>
    </div>
  );
};

const YoutubeEmbed = ({ embedId }: { embedId: string }) => (
  <div className="video-responsive">
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

const tags = [
  {
    title: "ANNOUNCEMENTS",
    description: `Admins can post important news and announcements for members. Members can view announcements`,
  },
  {
    title: "FINANCES",
    description:
      "Members can easily make due payments and donations through the App. Admins can see and manage due payments",
  },
  {
    title: "EVENTS",
    description: `Admins can post upcoming events.
Members can view events`,
  },
  { title: "SOCIALIZE", description: "Members can chat and Share Photos" },
];
const App = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#5e0483",
        flex: 1,
        display: "flex",
        color: "white",
        flexDirection: "column",
        alignItems: "center",
        padding: { md: 5, xs: 0 },
      }}
    >
      <Typography
        sx={{
          fontFamily: "Delius Swash Caps",
          padding: "0rem",
          fontWeight: "bold",
          mt: "2rem",
          fontSize: { md: "5rem", xs: "3rem" },
          textShadow: "2px 2px 2px rgba(0, 0, 0, 0.5)",
        }}
      >
        Associalize
      </Typography>
      <Typography
        sx={{
          fontSize: { md: "2rem", xs: "1rem" },
        }}
      >
        Connecting you with your association
      </Typography>
      <Box sx={{ padding: "1rem" }}>
        <img src={preview} alt="preview-inmage" style={{ width: "100%" }} />
      </Box>
      <Grid container spacing={2} sx={{ padding: 4 }}>
        {tags.map((t) => (
          <Grid item xs={12} md={6} key={t.title}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                fontSize={{ xs: "1.5rem", md: "2rem" }}
                fontWeight="bold"
              >
                {t.title}
              </Typography>
              <Typography fontSize={{ xs: "1rem", md: "1.5rem" }}>
                {t.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <YoutubeEmbed embedId="1e4G8rUfm1f4" />
      <div className="links">
        <a
          href="https://play.google.com/store/apps/details?id=com.appscanfly.associalize"
          target="_blank"
          rel="noreferrer"
        >
          <img src={googleImage} alt="google-play" />
        </a>
        <a
          href="https://apps.apple.com/us/app/associalize/id6451225842"
          target="_blank"
          rel="noreferrer"
        >
          <img src={appstore} alt="appstore" />
        </a>
      </div>
      <Footer />
    </Box>
    // <div className="App">
    //   <div className="container">
    //     <Info />
    //     <Screenshots />
    //   </div>{" "}
    //   <YoutubeEmbed embedId="1e4G8rUfm1f4" />
    //   <Footer />
    // </div>
  );
};

export default App;
