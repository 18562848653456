import React from "react";
import logo from "./logo.svg";
import "./App.css";
import googleImage from "./google-play-badge.png";
import appstore from "./appstore.png";
import preview from "./preview.png";

const Tos = () => {
  return (
    <div style={{ padding: "2rem", backgroundColor: "white" }}>
      <h3 style={{ marginTop: "0.2em!important" }}>Terms of Use</h3>
      <p>
        Last updated <span style={{ color: "#8351f9 " }}>11/14/2023</span>
      </p>
      <p>
        <strong>AGREEMENT TO TERMS</strong>
      </p>
      <p>
        These Terms and Conditions constitute a legally binding agreement made
        between you, whether personally or on behalf of an entity (“you”) and{" "}
        <span style={{ color: "#8351f9 " }}>Apps Can Fly, LLC</span> (“we,” “us”
        or “our”), concerning your access to and use of our mobile
        application&nbsp;(Associalize). You agree that by accessing the
        Application, you have read, understood, and agree to be bound by all of
        these Terms and Conditions Use. IF YOU DO NOT AGREE WITH ALL OF THESE
        TERMS AND CONDITIONS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
        APPLICATION AND YOU MUST DISCONTINUE USE IMMEDIATELY.
      </p>
      <p>
        Supplemental terms and conditions or documents that may be posted on the
        Application from time to time are hereby expressly incorporated herein
        by reference. We reserve the right, in our sole discretion, to make
        changes or modifications to these Terms and Conditions at any time and
        for any reason. We will alert you about any changes by updating the
        “Last updated” date of these Terms and Conditions and you waive any
        right to receive specific notice of each such change. It is your
        responsibility to periodically review these Terms and Conditions to stay
        informed of updates. You will be subject to, and will be deemed to have
        been made aware of and to have accepted, the changes in any revised
        Terms and Conditions by your continued use of the Application after the
        date such revised Terms are posted.
      </p>
      <p>
        The information provided on the Application is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country. Accordingly, those persons who
        choose to access the Application from other locations do so on their own
        initiative and are solely responsible for compliance with local laws, if
        and to the extent local laws are applicable.
      </p>

      <p>
        &nbsp;The Application is intended for users who are at least 13 years of
        age. All users who are minors in the jurisdiction in which they reside
        (generally under the age of 18) must have the permission of, and be
        directly supervised by, their parent or guardian to use the Application.
        If you are a minor, you must have your parent or guardian read and agree
        to these Terms of Use prior to you using the Application.
      </p>
      <p>
        <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
      </p>
      <p>
        Unless otherwise indicated, the Application is our proprietary property
        and all source code, databases, functionality, software, website
        designs, audio, video, text, photographs, and graphics on the
        Application (collectively, the “Content”) and the trademarks, service
        marks, and logos contained therein (the “Marks”) are owned or controlled
        by us or licensed to us, and are protected by copyright and trademark
        laws and various other intellectual property rights and unfair
        competition laws of the United States, foreign jurisdictions, and
        international conventions. The Content and the Marks are provided on the
        Application “AS IS” for your information and personal use only. Except
        as expressly provided in these Terms of Use, no part of the Application
        and no Content or Marks may be copied, reproduced, aggregated,
        republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any
        commercial purpose whatsoever, without our express prior written
        permission.
      </p>
      <p>
        Provided that you are eligible to use the Application, you are granted a
        limited license to access and use the Application and to download or
        print a copy of any portion of the Content to which you have properly
        gained access solely for your personal, non-commercial use. We reserve
        all rights not expressly granted to you in and to the Application,
        Content, and the Marks.
      </p>
      <p>
        <strong>USER REPRESENTATIONS</strong>
      </p>
      <p>
        By using the Application, you represent and warrant that: (1) all
        registration information you submit will be true, accurate, current, and
        complete; (2) you will maintain the accuracy of such information and
        promptly update such registration information as necessary; (3) you have
        the legal capacity and you agree to comply with these Terms of Use; (4)
        you are not under the age of 13;&nbsp;(5) not a minor in the
        jurisdiction in which you reside, or if a minor, you have received
        parental permission to use the Site; (6) you will not access the
        Application through automated or non-human means, whether through a bot,
        script or otherwise; (7) you will not use the Application for any
        illegal or unauthorized purpose; and (8) your use of the Application
        will not violate any applicable law or regulation.
      </p>
      <p>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse any and all current or future use of the Application(or any
        portion thereof).
      </p>
      <p>
        <strong>USER REGISTRATION</strong>
      </p>
      <p>
        You may be required to register with the Application. You agree to keep
        your password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </p>
      <p>
        <strong>PROHIBITED ACTIVITIES</strong>
      </p>
      <p>
        You may not access or use the Application for any purpose other than
        that for which we make the Application available. The Application may
        not be used in connection with any commercial endeavors except those
        that are specifically endorsed or approved by us.
      </p>
      <p>As a user of the Application, you agree not to:</p>

      <ol>
        <li>
          Systematically retrieve data or other content from the Application to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li>
          Make any unauthorized use of the Application, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email, or creating user
          accounts by automated means or under false pretenses.
        </li>
        <li>
          Use a buying agent or purchasing agent to make purchases on the
          Application.
        </li>
        <li>
          Use the Application to advertise or offer to sell goods and services.
        </li>
        <li>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Application, including features that prevent or
          restrict the use or copying of any Content or enforce limitations on
          the use of the Application and/or the Content contained therein.
        </li>
        <li>
          Engage in unauthorized framing of or linking to the Application.
        </li>
        <li>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords;
        </li>
        <li>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li>
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </li>
        <li>
          Interfere with, disrupt, or create an undue burden on the Application
          or the networks or services connected to the Application.
        </li>
        <li>
          Attempt to impersonate another user or person or use the username of
          another user.
        </li>
        <li>Sell or otherwise transfer your profile.</li>
        <li>
          Use any information obtained from the Application in order to harass,
          abuse, or harm another person.
        </li>
        <li>
          Use the Application as part of any effort to compete with us or
          otherwise use the Application and/or the Content for any
          revenue-generating endeavor or commercial enterprise.
        </li>
        <li>
          Decipher, decompile, disassemble, or reverse engineer any of the
          software comprising or in any way making up a part of the Application.
        </li>
        <li>
          Attempt to bypass any measures of the Application designed to prevent
          or restrict access to the Application, or any portion of the
          Application.
        </li>
        <li>
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the Application to you.
        </li>
        <li>
          Delete the copyright or other proprietary rights notice from any
          Content.
        </li>
        <li>
          Copy or adapt theApplication’s software, including but not limited to
          Flash, PHP, HTML, JavaScript, or other code.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of the
          Application or modifies, impairs, disrupts, alters, or interferes with
          the use, features, functions, operation, or maintenance of the
          Application.
        </li>
        <li>
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as “spyware” or “passive
          collection mechanisms” or “pcms”).
        </li>
        <li>
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Application, or
          using or launching any unauthorized script or other software.
        </li>
        <li>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Application.
        </li>
        <li>
          Use the Application in a manner inconsistent with any applicable laws
          or regulations.
        </li>
      </ol>
      <p>
        <strong>USER GENERATED CONTRIBUTIONS</strong>
      </p>
      <p>
        The Application may invite you to chat, contribute to, or participate in
        blogs, message boards, online forums, and other functionality, and may
        provide you with the opportunity to create, submit, post, display,
        transmit, perform, publish, distribute, or broadcast content and
        materials to us or on the Application, including but not limited to
        text, writings, video, audio, photographs, graphics, comments,
        suggestions, or personal information or other material (collectively,
        “Contributions”). Contributions may be viewable by other users of the
        Application and through third-party websites. As such, any Contributions
        you transmit may be treated as non-confidential and non-proprietary.
        When you create or make available any Contributions, you thereby
        represent and warrant that:
      </p>

      <ol>
        <li>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Application, and other users of the Application to use your
          Contributions in any manner contemplated by the Application and these
          Terms of Use.
        </li>
        <li>
          You have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness of each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Application and these Terms of Use.
        </li>
        <li>Your Contributions are not false, inaccurate, or misleading.</li>
        <li>
          Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </li>
        <li>
          Your Contributions are not obscene, lewd, lascivious, filthy, violent,
          harassing, libelous, slanderous, or otherwise objectionable (as
          determined by us).
        </li>
        <li>
          Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </li>
        <li>
          Your Contributions do not advocate the violent overthrow of any
          government or incite, encourage, or threaten physical harm against
          another.
        </li>
        <li>
          Your Contributions do not violate any applicable law, regulation, or
          rule.
        </li>
        <li>
          Your Contributions do not violate the privacy or publicity rights of
          any third party.
        </li>
        <li>
          Your Contributions do not contain any material that solicits personal
          information from anyone under the age of 18 or exploits people under
          the age of 18 in a sexual or violent manner.
        </li>
        <li>
          Your Contributions do not violate any federal or state law concerning
          child pornography, or otherwise intended to protect the health or
          well-being of minors;
        </li>
        <li>
          Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </li>
        <li>
          Your Contributions do not otherwise violate, or link to material that
          violates, any provision of these Terms of Use, or any applicable law
          or regulation.
        </li>
      </ol>
      <p>
        Any use of the Application in violation of the foregoing violates these
        Terms of Use and may result in, among other things, termination or
        suspension of your rights to use the Application.
      </p>
      <p>
        <strong>CONTRIBUTION LICENSE</strong>
      </p>
      <p>
        By posting your Contributions to any part of the Application, or making
        Contributions accessible to the Application by linking your account from
        the Application to any of your social networking accounts, you
        automatically grant, and you represent and warrant that you have the
        right to grant, to us an unrestricted, unlimited, irrevocable,
        perpetual, non-exclusive, transferable, royalty-free, fully-paid,
        worldwide right, and license to host, use, copy, reproduce, disclose,
        sell, resell, publish, broadcast, retitle, archive, store, cache,
        publicly perform, publicly display, reformat, translate, transmit,
        excerpt (in whole or in part), and distribute such Contributions
        (including, without limitation, your image and voice) for any purpose,
        commercial, advertising, or otherwise, and to prepare derivative works
        of, or incorporate into other works, such Contributions, and grant and
        authorize sublicenses of the foregoing. The use and distribution may
        occur in any media formats and through any media channels.
      </p>
      <p>
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. You waive all moral rights in your Contributions, and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.
      </p>
      <p>
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the Application. You are
        solely responsible for your Contributions to the Application and you
        expressly agree to exonerate us from any and all responsibility and to
        refrain from any legal action against us regarding your Contributions.
      </p>
      <p>
        We have the right, in our sole and absolute discretion, (1) to edit,
        redact, or otherwise change any Contributions; (2) to re-categorize any
        Contributions to place them in more appropriate locations on the
        Application; and (3) to pre-screen or delete any Contributions at any
        time and for any reason, without notice. We have no obligation to
        monitor your Contributions.
      </p>
      <p>
        <strong>GUIDELINES FOR REVIEWS</strong>
      </p>
      <p>
        We may provide you areas on the Application to leave reviews or ratings.
        When posting a review, you must comply with the following criteria: (1)
        you should have firsthand experience with the person/entity being
        reviewed; (2) your reviews should not contain offensive profanity, or
        abusive, racist, offensive, or hate language; (3) your reviews should
        not contain discriminatory references based on religion, race, gender,
        national origin, age, marital status, sexual orientation, or disability;
        (4) your reviews should not contain references to illegal activity; (5)
        you should not be affiliated with competitors if posting negative
        reviews; (6) you should not make any conclusions as to the legality of
        conduct; (7) you may not post any false or misleading statements; and
        (8) you may not organize a campaign encouraging others to post reviews,
        whether positive or negative.
      </p>
      <p>
        We may accept, reject, or remove reviews in our sole discretion. We have
        absolutely no obligation to screen reviews or to delete reviews, even if
        anyone considers reviews objectionable or inaccurate. Reviews are not
        endorsed by us, and do not necessarily represent our opinions or the
        views of any of our affiliates or partners. We do not assume liability
        for any review or for any claims, liabilities, or losses resulting from
        any review. By posting a review, you hereby grant to us a perpetual,
        non-exclusive, worldwide, royalty-free, fully-paid, assignable, and
        sublicensable right and license to reproduce, modify, translate,
        transmit by any means, display, perform, and/or distribute all content
        relating to reviews.<strong>&nbsp;</strong>
      </p>
      <p>
        <strong>MOBILE APPLICATION LICENSE</strong>
      </p>
      <p>
        <strong>Use License</strong>
      </p>
      <p>
        If you access the Application via a mobile application, then we grant
        you a revocable, non-exclusive, non-transferable, limited right to
        install and use the mobile application on wireless electronic devices
        owned or controlled by you, and to access and use the mobile application
        on such devices strictly in accordance with the terms and conditions of
        this mobile application license contained in these Terms of Use. You
        shall not: (1) decompile, reverse engineer, disassemble, attempt to
        derive the source code of, or decrypt the application; (2) make any
        modification, adaptation, improvement, enhancement, translation, or
        derivative work from the application; (3) violate any applicable laws,
        rules, or regulations in connection with your access or use of the
        application; (4) remove, alter, or obscure any proprietary notice
        (including any notice of copyright or trademark) posted by us or the
        licensors of the application; (5) use the application for any revenue
        generating endeavor, commercial enterprise, or other purpose for which
        it is not designed or intended; (6) make the application available over
        a network or other environment permitting access or use by multiple
        devices or users at the same time; (7) use the application for creating
        a product, service, or software that is, directly or indirectly,
        competitive with or in any way a substitute for the application; (8) use
        the application to send automated queries to any website or to send any
        unsolicited commercial e-mail; or (9) use any proprietary information or
        any of our interfaces or our other intellectual property in the design,
        development, manufacture, licensing, or distribution of any
        applications, accessories, or devices for use with the application.
      </p>
      <p>
        <strong>Apple and Android Devices</strong>
      </p>
      <p>
        The following terms apply when you use a mobile application obtained
        from either the Apple Store or Google Play (each an “App Distributor”)
        to access the Application: (1) the license granted to you for our mobile
        application is limited to a non-transferable license to use the
        application on a device that utilizes the Apple iOS or Android operating
        systems, as applicable, and in accordance with the usage rules set forth
        in the applicable App Distributor’s terms of service; (2) we are
        responsible for providing any maintenance and support services with
        respect to the mobile application as specified in the terms and
        conditions of this mobile application license contained in these Terms
        of Use or as otherwise required under applicable law, and you
        acknowledge that each App Distributor has no obligation whatsoever to
        furnish any maintenance and support services with respect to the mobile
        application; (3) in the event of any failure of the mobile application
        to conform to any applicable warranty, you may notify the applicable App
        Distributor, and the App Distributor, in accordance with its terms and
        policies, may refund the purchase price, if any, paid for the mobile
        application, and to the maximum extent permitted by applicable law, the
        App Distributor will have no other warranty obligation whatsoever with
        respect to the mobile application; (4) you represent and warrant that
        (i) you are not located in a country that is subject to a U.S.
        government embargo, or that has been designated by the U.S. government
        as a “terrorist supporting” country and (ii) you are not listed on any
        U.S. government list of prohibited or restricted parties; (5) you must
        comply with applicable third-party terms of agreement when using the
        mobile application, e.g., if you have a VoIP application, then you must
        not be in violation of their wireless data service agreement when using
        the mobile application; and (6) you acknowledge and agree that the App
        Distributors are third-party beneficiaries of the terms and conditions
        in this mobile application license contained in these Terms of Use, and
        that each App Distributor will have the right (and will be deemed to
        have accepted the right) to enforce the terms and conditions in this
        mobile application license contained in these Terms of Use against you
        as a third-party beneficiary thereof.
      </p>
      <p>
        <strong>SOCIAL MEDIA</strong>
      </p>
      <p>
        As part of the functionality of the Application, you may link your
        account with online accounts you have with third-party service providers
        (each such account, a “Third-Party Account”) by either: (1) providing
        your Third-Party Account login information through the Application; or
        (2) allowing us to access your Third-Party Account, as is permitted
        under the applicable terms and conditions that govern your use of each
        Third-Party Account. You represent and warrant that you are entitled to
        disclose your Third-Party Account login information to us and/or grant
        us access to your Third-Party Account, without breach by you of any of
        the terms and conditions that govern your use of the applicable
        Third-Party Account, and without obligating us to pay any fees or making
        us subject to any usage limitations imposed by the third-party service
        provider of the Third-Party Account. By granting us access to any
        Third-Party Accounts, you understand that (1) we may access, make
        available, and store (if applicable) any content that you have provided
        to and stored in your Third-Party Account (the “Social Network Content”)
        so that it is available on and through the Application via your account,
        including without limitation any friend lists and (2) we may submit to
        and receive from your Third-Party Account additional information to the
        extent you are notified when you link your account with the Third-Party
        Account. Depending on the Third-Party Accounts you choose and subject to
        the privacy settings that you have set in such Third-Party Accounts,
        personally identifiable information that you post to your Third-Party
        Accounts may be available on and through your account on the
        Application. Please note that if a Third-Party Account or associated
        service becomes unavailable or our access to such Third-Party Account is
        terminated by the third-party service provider, then Social Network
        Content may no longer be available on and through the Application. You
        will have the ability to disable the connection between your account on
        the Application and your Third-Party Accounts at any time. PLEASE NOTE
        THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
        WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S)
        WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any
        Social Network Content for any purpose, including but not limited to,
        for accuracy, legality, or non-infringement, and we are not responsible
        for any Social Network Content. You acknowledge and agree that we may
        access your email address book associated with a Third-Party Account and
        your contacts list stored on your mobile device or tablet computer
        solely for purposes of identifying and informing you of those contacts
        who have also registered to use the Application. You can deactivate the
        connection between the Application and your Third-Party Account by
        contacting us using the contact information below or through your
        account settings (if applicable). We will attempt to delete any
        information stored on our servers that was obtained through such
        Third-Party Account, except the username and profile picture that become
        associated with your account.
      </p>
      <p>
        <strong>SUBMISSIONS</strong>
      </p>
      <p>
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, feedback, or other information regarding the Application
        (“Submissions”) provided by you to us are non-confidential and shall
        become our sole property. We shall own exclusive rights, including all
        intellectual property rights, and shall be entitled to the unrestricted
        use and dissemination of these Submissions for any lawful purpose,
        commercial or otherwise, without acknowledgment or compensation to you.
        You hereby waive all moral rights to any such Submissions, and you
        hereby warrant that any such Submissions are original with you or that
        you have the right to submit such Submissions. You agree there shall be
        no recourse against us for any alleged or actual infringement or
        misappropriation of any proprietary right in your Submissions.
      </p>
      <p>
        <strong>THIRD-PARTY WEBSITES AND CONTENT</strong>
      </p>
      <p>
        The Application may contain (or you may be sent via the Application)
        links to other websites (“Third-Party Websites”) as well as articles,
        photographs, text, graphics, pictures, designs, music, sound, video,
        information, applications, software, and other content or items
        belonging to or originating from third parties (“Third-Party Content”).
        Such Third-Party Websites and Third-Party Content are not investigated,
        monitored, or checked for accuracy, appropriateness, or completeness by
        us, and we are not responsible for any Third-Party Websites accessed
        through the Application or any Third-Party Content posted on, available
        through, or installed from the Application, including the content,
        accuracy, offensiveness, opinions, reliability, privacy practices, or
        other policies of or contained in the Third-Party Websites or the
        Third-Party Content. Inclusion of, linking to, or permitting the use or
        installation of any Third-Party Websites or any Third-Party Content does
        not imply approval or endorsement thereof by us. If you decide to leave
        the Application and access the Third-Party Websites or to use or install
        any Third-Party Content, you do so at your own risk, and you should be
        aware these Terms of Use no longer govern. You should review the
        applicable terms and policies, including privacy and data gathering
        practices, of any website to which you navigate from the Application or
        relating to any applications you use or install from the Application.
        Any purchases you make through Third-Party Websites will be through
        other websites and from other companies, and we take no responsibility
        whatsoever in relation to such purchases which are exclusively between
        you and the applicable third party. You agree and acknowledge that we do
        not endorse the products or services offered on Third-Party Websites and
        you shall hold us harmless from any harm caused by your purchase of such
        products or services. Additionally, you shall hold us harmless from any
        losses sustained by you or harm caused to you relating to or resulting
        in any way from any Third-Party Content or any contact with Third-Party
        Websites.
      </p>

      <p>
        <strong>APP MANAGEMENT</strong>
      </p>
      <p>
        We reserve the right, but not the obligation, to:&nbsp;(1) monitor the
        Application for violations of these Terms of Use; (2) take appropriate
        legal action against anyone who, in our sole discretion, violates the
        law or these Terms of Use, including without limitation, reporting such
        user to law enforcement authorities; (3) in our sole discretion and
        without limitation, refuse, restrict access to, limit the availability
        of, or disable (to the extent technologically feasible) any of your
        Contributions or any portion thereof; (4) in&nbsp;our sole discretion
        and without limitation, notice, or liability, to remove from the
        Application or otherwise disable all files and content that are
        excessive in size or are in any way burdensome to our systems; and (5)
        otherwise manage the Application in a manner designed to protect our
        rights and property and to facilitate the proper functioning of the
        Application.
      </p>

      <p>
        <strong>TERM AND TERMINATION</strong>
      </p>
      <p>
        These Terms of Use shall remain in full force and effect while you use
        the Application. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
        USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE
        OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
        OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR
        DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
        ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </p>
      <p>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </p>
      <p>
        <strong>MODIFICATIONS AND INTERRUPTIONS</strong>
      </p>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Application at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Application. We also reserve the right to modify or discontinue all or
        part of the Application without notice at any time. We will not be
        liable to you or any third party for any modification, price change,
        suspension, or discontinuance of the Application.
      </p>
      <p>
        We cannot guarantee the Application will be available at all times. We
        may experience hardware, software, or other problems or need to perform
        maintenance related to the Application, resulting in interruptions,
        delays, or errors. We reserve the right to change, revise, update,
        suspend, discontinue, or otherwise modify the Application at any time or
        for any reason without notice to you. You agree that we have no
        liability whatsoever for any loss, damage, or inconvenience caused by
        your inability to access or use the Application during any downtime or
        discontinuance of the Application. Nothing in these Terms of Use will be
        construed to obligate us to maintain and support the Application or to
        supply any corrections, updates, or releases in connection therewith.
      </p>

      <p>
        <strong>CORRECTIONS</strong>
      </p>
      <p>
        There may be information on the Application that contains typographical
        errors, inaccuracies, or omissions that may relate to the Application,
        including descriptions, pricing, availability, and various other
        information. We reserve the right to correct any errors, inaccuracies,
        or omissions and to change or update the information on the Application
        at any time, without prior notice.
      </p>
      <p>
        <strong>DISCLAIMER</strong>
      </p>
      <p>
        THE APPLICATION IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
        AGREE THAT YOUR USE OF THE APPLICATION SERVICES WILL BE AT YOUR SOLE
        RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
        WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE APPLICATION AND
        YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
        NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
        ACCURACY OR COMPLETENESS OF THE APPLICATION’S CONTENT OR THE CONTENT OF
        ANY WEBSITES LINKED TO THIS APPLICATION AND WE WILL ASSUME NO LIABILITY
        OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
        CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
        NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
        APPLICATION, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
        AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
        STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
        FROM THE APPLICATION, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
        WHICH MAY BE TRANSMITTED TO OR THROUGH THE APPLICATION BY ANY THIRD
        PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS
        OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
        ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
        APPLICATION. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
        RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
        THIRD PARTY THROUGH THE APPLICATION, ANY HYPERLINKED WEBSITE, OR ANY
        WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
        ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
        FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS
        OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
        THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
        JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </p>

      <p>
        <strong>INDEMNIFICATION</strong>
      </p>
      <p>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (1) your
        Contributions; (2) use of the Site; (3) breach of these Terms of Use;
        (4) any breach of your representations and warranties set forth in these
        Terms of Use; (5) your violation of the rights of a third party,
        including but not limited to intellectual property rights; or (6) any
        overt harmful act toward any other user of the Site with whom you
        connected via the Site. Notwithstanding the foregoing, we reserve the
        right, at your expense, to assume the exclusive defense and control of
        any matter for which you are required to indemnify us, and you agree to
        cooperate, at your expense, with our defense of such claims. We will use
        reasonable efforts to notify you of any such claim, action, or
        proceeding which is subject to this indemnification upon becoming aware
        of it.
      </p>
      <p>
        <strong>USER DATA</strong>
      </p>
      <p>
        We will maintain certain data that you transmit to the Site for the
        purpose of managing the Site, as well as data relating to your use of
        the Site. Although we perform regular routine backups of data, you are
        solely responsible for all data that you transmit or that relates to any
        activity you have undertaken using the Site. You agree that we shall
        have no liability to you for any loss or corruption of any such data,
        and you hereby waive any right of action against us arising from any
        such loss or corruption of such data.
      </p>
      <p>
        <strong>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong>
      </p>
      <p>
        Visiting the Site, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Site, satisfy any legal requirement that such communication be in
        writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SITE. You hereby waive any rights or requirements under
        any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>
      <p>
        <strong>CALIFORNIA USERS AND RESIDENTS</strong>
      </p>
      <p>
        If any complaint with us is not satisfactorily resolved, you can contact
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs in writing at 1625 North
        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
        at (800) 952-5210 or (916) 445-1254.
      </p>
      <p>
        <strong>MISCELLANEOUS</strong>
      </p>
      <p>
        These Terms of Use and any policies or operating rules posted by us on
        the Application constitute the entire agreement and understanding
        between you and us. Our failure to exercise or enforce any right or
        provision of these Terms of Use shall not operate as a waiver of such
        right or provision. These Terms of Use operate to the fullest extent
        permissible by law. We may assign any or all of our rights and
        obligations to others at any time. We shall not be responsible or liable
        for any loss, damage, delay, or failure to act caused by any cause
        beyond our reasonable control. If any provision or part of a provision
        of these Terms of Use is determined to be unlawful, void, or
        unenforceable, that provision or part of the provision is deemed
        severable from these Terms of Use and does not affect the validity and
        enforceability of any remaining provisions. There is no joint venture,
        partnership, employment or agency relationship created between you and
        us as a result of these Terms of Use or use of the Site. You agree that
        these Terms of Use will not be construed against us by virtue of having
        drafted them. You hereby waive any and all defenses you may have based
        on the electronic form of these Terms of Use and the lack of signing by
        the parties hereto to execute these Terms of Use.
      </p>
      <p>
        <strong>CONTACT US</strong>
      </p>
      <p>
        In order to resolve a complaint regarding the Application or to receive
        further information regarding use of the Application, please contact us
        at:
      </p>
      <p>
        <span style={{ color: "#8351f9 " }}>Apps Can Fly, LLC</span>
      </p>
      <p>
        <span style={{ color: "#8351f9 " }}>
          122 E Main Str #158, Lakeland, FL 33801
        </span>
      </p>
      <p>
        <span style={{ color: "#8351f9 " }}>support@appscanfly.com</span>
      </p>

      <h1>Privacy Policy</h1>
      <p>Last updated: August 15, 2023</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy. This Privacy Policy has been
        created with the help of the{" "}
        <a
          href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
          target="_blank"
          rel="noreferrer"
        >
          Free Privacy Policy Generator
        </a>
        .
      </p>
      <h1>Interpretation and Definitions</h1>
      <h2>Interpretation</h2>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h2>Definitions</h2>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <p>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </p>
        </li>
        <li>
          <p>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority.
          </p>
        </li>
        <li>
          <p>
            <strong>Application</strong> refers to Associalize, the software
            program provided by the Company.
          </p>
        </li>
        <li>
          <p>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to Apps Can Fly, LLC, 122 E Main str #158,
            Lakeland FL 33801.
          </p>
        </li>
        <li>
          <p>
            <strong>Country</strong> refers to: Florida, United States
          </p>
        </li>
        <li>
          <p>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </p>
        </li>
        <li>
          <p>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </p>
        </li>
        <li>
          <p>
            <strong>Service</strong> refers to the Application.
          </p>
        </li>
        <li>
          <p>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </p>
        </li>
        <li>
          <p>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </p>
        </li>
        <li>
          <p>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </p>
        </li>
      </ul>
      <h1>Collecting and Using Your Personal Data</h1>
      <h2>Types of Data Collected</h2>
      <h3>Personal Data</h3>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <ul>
        <li>
          <p>Email address</p>
        </li>
        <li>
          <p>First name and last name</p>
        </li>
        <li>
          <p>Phone number</p>
        </li>
        <li>
          <p>Usage Data</p>
        </li>
      </ul>
      <h3>Usage Data</h3>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>
      <h3>Information Collected while Using the Application</h3>
      <p>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:
      </p>
      <ul>
        <li>
          Pictures and other information from your Device's camera and photo
          library
        </li>
      </ul>
      <p>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company's servers and/or a Service Provider's server or it may be simply
        stored on Your device.
      </p>
      <p>
        You can enable or disable access to this information at any time,
        through Your Device settings.
      </p>
      <h2>Use of Your Personal Data</h2>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li>
          <p>
            <strong>To provide and maintain our Service</strong>, including to
            monitor the usage of our Service.
          </p>
        </li>
        <li>
          <p>
            <strong>To manage Your Account:</strong> to manage Your registration
            as a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
          </p>
        </li>
        <li>
          <p>
            <strong>For the performance of a contract:</strong> the development,
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service.
          </p>
        </li>
        <li>
          <p>
            <strong>To contact You:</strong> To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding updates
            or informative communications related to the functionalities,
            products or contracted services, including the security updates,
            when necessary or reasonable for their implementation.
          </p>
        </li>
        <li>
          <p>
            <strong>To provide You</strong> with news, special offers and
            general information about other goods, services and events which we
            offer that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </p>
        </li>
        <li>
          <p>
            <strong>To manage Your requests:</strong> To attend and manage Your
            requests to Us.
          </p>
        </li>
        <li>
          <p>
            <strong>For business transfers:</strong> We may use Your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </p>
        </li>
        <li>
          <p>
            <strong>For other purposes</strong>: We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </p>
        </li>
      </ul>
      <p>We may share Your personal information in the following situations:</p>
      <ul>
        <li>
          <strong>With Service Providers:</strong> We may share Your personal
          information with Service Providers to monitor and analyze the use of
          our Service, to contact You.
        </li>
        <li>
          <strong>For business transfers:</strong> We may share or transfer Your
          personal information in connection with, or during negotiations of,
          any merger, sale of Company assets, financing, or acquisition of all
          or a portion of Our business to another company.
        </li>
        <li>
          <strong>With Affiliates:</strong> We may share Your information with
          Our affiliates, in which case we will require those affiliates to
          honor this Privacy Policy. Affiliates include Our parent company and
          any other subsidiaries, joint venture partners or other companies that
          We control or that are under common control with Us.
        </li>
        <li>
          <strong>With business partners:</strong> We may share Your information
          with Our business partners to offer You certain products, services or
          promotions.
        </li>
        <li>
          <strong>With other users:</strong> when You share personal information
          or otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside.
        </li>
        <li>
          <strong>With Your consent</strong>: We may disclose Your personal
          information for any other purpose with Your consent.
        </li>
      </ul>
      <h2>Retention of Your Personal Data</h2>
      <p>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </p>
      <p>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>
      <h2>Transfer of Your Personal Data</h2>
      <p>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>
      <h2>Delete Your Personal Data</h2>
      <p>
        You have the right to delete or request that We assist in deleting the
        Personal Data that We have collected about You.
      </p>
      <p>
        Our Service may give You the ability to delete certain information about
        You from within the Service.
      </p>
      <p>
        You may update, amend, or delete Your information at any time by signing
        in to Your Account, if you have one, and visiting the account settings
        section that allows you to manage Your personal information. You may
        also contact Us to request access to, correct, or delete any personal
        information that You have provided to Us.
      </p>
      <p>
        Please note, however, that We may need to retain certain information
        when we have a legal obligation or lawful basis to do so.
      </p>
      <h2>Disclosure of Your Personal Data</h2>
      <h3>Business Transactions</h3>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <h3>Law enforcement</h3>
      <p>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <h3>Other legal requirements</h3>
      <p>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </ul>
      <h2>Security of Your Personal Data</h2>
      <p>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <h1>Children's Privacy</h1>
      <p>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </p>
      <p>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.
      </p>
      <h1>Links to Other Websites</h1>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h1>Changes to this Privacy Policy</h1>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the &quot;Last
        updated&quot; date at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h1>Contact Us</h1>
      <p>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul>
        <li>By email: support@appscanfly.com</li>
      </ul>
    </div>
  );
};

export default Tos;
